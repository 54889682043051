import { useInternalFetch, useInternalFetchWithAuth } from "@/utils/fetch";
import { UseFetchOptions } from "@vueuse/core";
export interface LoginInput {
  username: string | null;
  password: string | null;
}

export function useAuthService() {
  async function login(payload: LoginInput) {
    return await useInternalFetch(`${import.meta.env.VITE_APP_API_URL}/login`, {
      headers: {
        "Content-type": "application/json",
        "Accept-Language": "th",
      },
      method: "POST",
      body: JSON.stringify(payload),
    } as UseFetchOptions).json();
  }

  async function logout() {
    const fetch = await useInternalFetchWithAuth(
      `${import.meta.env.VITE_APP_API_URL}/logout`,
      {
        method: "POST",
      }
    ).json();
    return fetch;
  }

  return { logout, login };
}
