import { defineStore } from 'pinia'

interface State {
  loaders: string[]
}

export const useLoaderStore = defineStore('loader', {
  state: (): State => ({
    loaders: [],
  }),
  getters: {
    isWait: (state) => (key: string[]) => {
      return state.loaders.some((el) => key.includes(el))
    },
  },
  actions: {
    wait(key: string) {
      this.loaders.push(key)
    },
    endWait(key: string) {
      this.loaders = this.loaders.filter((el) => el !== key)
    },
  },
})
