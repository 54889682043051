import { CreateAccountInput } from "../stores/member";
import { useInternalFetchWithAuth } from "../utils/fetch";

export interface UpdatePassword {
  currentPassword: string;
  newPassword: string;
}

export const useMemberService = () => {
  function onFetchMemberProfileByTokenService() {
    const url = `user/profile`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onFetchMemberProfileService(id: number) {
    const url = `user/profile/${id}`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onFetchMemberCommissionService(id: number) {
    const url = `user/${id}/commission`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onFetchMemberShareService(id: number) {
    const url = `user/share/${id}`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onFetchMemberChildListService(level: number) {
    const url = `user/child/list/role/${level}`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onFetchMemberUserChildListService(parentId: number, role: number) {
    const url = `user/child/list?parentId=${parentId}&role=${role}`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onCheckDuplicateUsernameService(username: string) {
    const url = `member/duplicate?username=${username}`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function onCreateMemberAccountService(input: CreateAccountInput) {
    const url = `member`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "POST",
      body: JSON.stringify(input),
    }).json();
    return fetch;
  }

  function onFetchMemberAllParentService(id: number, level: number) {
    const url = `user/parent/all/${id}/${level}`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "GET",
    }).json();
    return fetch;
  }

  function updateUserPasswordService(input: UpdatePassword) {
    const url = `user/change-password`;
    const fetch = useInternalFetchWithAuth(url, {
      method: "PATCH",
      body: JSON.stringify(input),
    }).json();
    return fetch;
  }

  return {
    onFetchMemberProfileByTokenService,
    onFetchMemberProfileService,
    onFetchMemberCommissionService,
    onFetchMemberShareService,
    onCheckDuplicateUsernameService,
    onCreateMemberAccountService,
    onFetchMemberAllParentService,
    onFetchMemberChildListService,
    onFetchMemberUserChildListService,
    updateUserPasswordService,
  };
};
