<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import { useI18n } from "vue-i18n";

import FormSelect from "@/components/Base/Form/FormSelect.vue";
import Button from "@/components/Base/Button/Button.vue";

import { useAuthStore } from "@/stores/auth";
import { useLoaderStore } from "@/stores/loader";
import { MemberLevelLabel } from "@/constant/global";

import { formatDecimalNumber } from "@/utils/format-number";
import { useAuthService } from "@/composable/auth.service";
import langImgEn from "@/assets/images/flags/en.png";
import langImgTh from "@/assets/images/flags/th.png";

const router = useRouter();
const authStore = useAuthStore();
const { logout } = useAuthService();
const { t, locale } = useI18n();
const currentLanguage = useLocalStorage("i18n", "en");

const localeOption = computed(() => [
  {
    language: "en",
    label: "English",
    image: langImgEn,
  },
  {
    language: "th",
    label: "ภาษาไทย",
    image: langImgTh,
  },
]);

const languageFlag = computed(() =>
  currentLanguage.value === localeOption.value[0].language
    ? localeOption.value[0].image
    : localeOption.value[1].image
);

const sessionMember = computed(() => authStore.sessionMember);

async function onLogout() {
  await logout();
  authStore.$reset();
  localStorage.removeItem("authToken");
  await router.push("/login");
}

watch(currentLanguage, (newValue) => {
  locale.value = newValue;
});

onMounted(() => {
  locale.value = currentLanguage.value;
});
</script>

<template>
  <div
    class="px-2 py-2 w-full flex gap-2 items-center bg-top-bar min-w-[1220px]"
  >
    <div
      class="px-6 py-1 flex gap-8 text-black bg-slate-100 rounded-full font-extrabold bg-top-bar-inner-box"
    >
      <div class="top-bar-text">
        {{ t("user.title") }}:
        <span class="pl-1 uppercase"> {{ sessionMember?.username }} </span>
      </div>
      <div class="top-bar-text">
        {{ t("level.title") }}:
        <span class="pl-1 uppercase">
          {{
            sessionMember?.role
              ? MemberLevelLabel[sessionMember.role as MemberLevelLabel]
              : ""
          }}
        </span>
      </div>
      <div class="top-bar-text">
        {{ t("credit") }}:
        <span class="pl-1">
          {{ formatDecimalNumber(sessionMember?.userProfile.balance ?? 0, 0) }}
        </span>
      </div>
      <div class="top-bar-text">
        {{ t("language.title") }}:
        <div class="pl-1 flex gap-2 items-center">
          <img :src="languageFlag" alt="language" class="w-5 h-3" />
          <FormSelect
            v-model="currentLanguage"
            class="w-[90px] h-[25px] font-light text-xs !p-0 !pl-2 rounded-md border-solid border-1 border-black"
          >
            <option v-for="item in localeOption" :value="item.language">
              {{ item.label }}
            </option>
          </FormSelect>
        </div>
      </div>
    </div>
    <Button
      class="bg-yellow-500 border-yellow-500 text-slate-700"
      size="xs"
      @click="onLogout"
    >
      {{ t("user.LogOut") }}
    </Button>
  </div>
</template>

<style lang="postcss">
.bg-top-bar {
  background: linear-gradient(135deg, #343d46 0%, #748c9e 100%);
  box-shadow: 1px 1px 5px 0 #333;
  .bg-top-bar-inner-box {
    box-shadow: inset 0 1px 2px 1px #666;
  }
  .top-bar-text {
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    font-family: Tahoma;
  }
}
</style>
