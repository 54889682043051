<script setup lang="ts">
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import { useI18n } from "vue-i18n";

import { FormInput, FormSelect } from "@/components/Base/Form";

import { useLoaderStore } from "@/stores/loader";
import { useAuthService } from "@/composable/auth.service";

import langImgEn from "@/assets/images/flags/en.png";
import langImgTh from "@/assets/images/flags/th.png";

const router = useRouter();
const loaderStore = useLoaderStore();
const { login } = useAuthService();
const { t, locale } = useI18n();
const currentLanguage = useLocalStorage("i18n", "en");

const loading = computed(() => loaderStore.isWait(["auth:login"]));

const usernameInput = ref("");
const passwordInput = ref("");
const errorMessages = reactive<{
  username: string | null;
  password: string | null;
}>({
  username: null,
  password: null,
});

const localeOption = computed(() => [
  {
    language: "en",
    label: "English",
    image: langImgEn,
  },
  {
    language: "th",
    label: "ภาษาไทย",
    image: langImgTh,
  },
]);

const lang = computed(() =>
  currentLanguage.value === localeOption.value[0].language
    ? localeOption.value[0].image
    : localeOption.value[1].image
);

const onSubmit = async () => {
  loaderStore.wait("auth:login");
  errorMessages.username = null;
  errorMessages.password = null;
  const { data: loginData, response } = await login({
    username: usernameInput.value,
    password: passwordInput.value,
  });
  if (response.value?.ok) {
    localStorage.setItem("authToken", loginData.value.token);
    await router.replace({ name: "my-profile" });
  } else {
    if (loginData.value) {
      if (loginData.value?.errorCode === "UserNotAllowed") {
        errorMessages.username = "Allow agent login only";
      } else if (loginData.value?.errorCode === "UserIsLocked") {
        errorMessages.password = "This user is locked";
      } else {
        errorMessages.password = "Incorrect username or password";
      }
    } else {
      errorMessages.password = "Something went wrong. Internal server error";
    }
  }
  loaderStore.endWait("auth:login");
};

watch(currentLanguage, (newValue) => {
  locale.value = newValue;
});

onMounted(() => {
  locale.value = currentLanguage.value;
});
</script>

<template>
  <div class="flex justify-center bg-login-page h-full w-full">
    <div
      class="h-[320px] w-[540px] p-[15px] rounded-xl bg-cover bg-center bg-[url('@/assets/images/bg2.png')] mt-5 shadow-[5px_0_15px_2px_rgba(0,0,0)]"
    >
      <div class="flex justify-end font-bold mt-3" @submit.prevent="onSubmit">
        <form class="w-[220px] text-[13px]">
          <div class="flex justify-end mb-2">
            <label
              class="block font-bold leading-6 text-[#000066] whitespace-nowrap mr-4"
            >
              {{ t("language.title") }}
            </label>
            <div class="flex items-center">
              <FormSelect
                v-model="currentLanguage"
                class="w-[90px] h-[25px] font-light text-xs !p-0 !pl-2 rounded-md border-solid border-1 border-black"
              >
                <option v-for="item in localeOption" :value="item.language">
                  {{ item.label }}
                </option>
              </FormSelect>
              <span class="w-[23px] h-4 mx-2">
                <img :src="lang" class="h-full w-full object-cover" />
              </span>
            </div>
          </div>
          <hr />
          <table class="mt-2 login-form table-auto">
            <tbody class="grid flex-col justify-items-end">
              <tr class="grid grid-cols-5">
                <td class="col-span-2 flex justify-end">
                  <label
                    class="text-end font-bold leading-6 text-[#000066] whitespace-nowrap"
                  >
                    {{ t("user.loginName") }} :
                  </label>
                </td>
                <td class="grid">
                  <FormInput
                    v-model="usernameInput"
                    autocomplete="username"
                    required
                    :disabled="loading"
                    class="w-[120px] h-[25px] text-xs rounded-md border-solid border-1 border-black md:px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm: sm:leading-6"
                    :class="{ 'opacity-50': loading }"
                  />
                  <span class="text-red-600 text-xs">
                    {{ errorMessages.username }}
                  </span>
                </td>
              </tr>
              <tr class="grid grid-cols-5">
                <td class="col-span-2 flex justify-end">
                  <label
                    class="text-end font-bold leading-6 text-[#000066] whitespace-nowrap"
                  >
                    {{ t("user.password") }} :
                  </label>
                </td>
                <td class="grid">
                  <FormInput
                    v-model="passwordInput"
                    autocomplete="current-password"
                    type="password"
                    :disabled="loading"
                    required
                    class="w-[120px] h-[25px] text-xs rounded-md border-solid border-1 border-black md:px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm: sm:leading-6"
                    :class="{ 'opacity-50': loading }"
                  />
                  <span class="text-red-600 text-xs">
                    {{ errorMessages.password }}
                  </span>
                </td>
              </tr>
              <tr class="grid grid-cols-5">
                <td class="col-start-3">
                  <button
                    type="submit"
                    class="flex w-[100px] h-[28px] justify-center items-center rounded-md bg-[#490066] text-white font-bold mx-auto"
                    :class="{ 'cursor-wait opacity-35': loading }"
                  >
                    {{ loading ? t("loading") : t("user.signIn") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <p
            class="text-start text-gray-500 text-[11.5px] font-light leading-normal"
          >
            Notics:
            <br />
            This website is compatible with all browsers please download and
            install latest version.
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.bg-login-page {
  background: linear-gradient(to bottom, #5869c1 0%, #182562 100%);
}
.login-form {
  td {
    @apply border-none px-0 ml-0;
  }
}
</style>
