import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import "./assets/css/app.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/th";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import weekday from "dayjs/plugin/weekday";
import { th } from "@/lang/th";
import { en } from "@/lang/en";

import * as Sentry from "@sentry/vue";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en,
    th,
  },
  legacy: false,
});

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(i18n);

app.component("VueDatePicker", VueDatePicker);

app.mount("#app");

const apiUrl = import.meta.env.VITE_APP_API_URL;
if (apiUrl) {
  Sentry.init({
    app,
    dsn: "https://524fe15964e04d324e0189dbb423865b@o4507429629919232.ingest.us.sentry.io/4507429631688704",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", apiUrl],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "%s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});
