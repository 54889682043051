import { type Icon } from "@/components/Base/Lucide/Lucide.vue";
import { defineStore } from "pinia";
import sideMenu from "@/main/side-menu";

export interface Menu {
  no: string;
  title: string;
  pageName?: string;
  key?: string;
  subMenu?: Menu[];
  activeDropdown?: boolean;
  ignore?: boolean;
}

export interface MenuState {
  menuValue: Array<Menu | "divider">;
}

export const useMenuStore = defineStore("menu", {
  state: (): MenuState => ({
    menuValue: [],
  }),
  getters: {
    menu: (state) => () => {
      return sideMenu;
    },
  },
});
