import { type Menu } from "@/stores/menu";

const menu: Array<Menu | "divider"> = [
  {
    no: "1.",
    title: "totalBet.title",
    activeDropdown: true,
    key: "TOTAL_BET",
    subMenu: [
      {
        no: "1.1",
        pageName: "hdp-ou",
        title: "totalBet.sideMenu.simpleHdpOu",
      },
      {
        no: "1.2",
        pageName: "ht-hdp-ou",
        title: "totalBet.sideMenu.simpleHtHdpOu",
      },
      {
        no: "1.3",
        pageName: "ft-oe",
        title: "totalBet.sideMenu.simple1X2Oe",
      },
      {
        no: "1.4",
        pageName: "step",
        title: "totalBet.ballStep.title",
      },
      {
        no: "1.5",
        pageName: "single",
        title: "totalBet.singleBet",
      },
      {
        no: "1.6",
        pageName: "member",
        title: "totalBet.memberOutstanding",
      },
    ],
  },
  {
    no: "2.",
    title: "memberMgmt.title",
    activeDropdown: true,
    key: "MEMBER_MGMT",
    subMenu: [
      {
        no: "2.1",
        pageName: "new-account",
        title: "memberMgmt.newAccount.title",
        key: "NEW_ACCOUNT",
      },
      {
        no: "2.2",
        pageName: "account-list",
        title: "memberMgmt.accountList.title",
      },
      {
        no: "2.3",
        pageName: "betting",
        title: "memberMgmt.betting.title",
      },
      {
        no: "2.4",
        pageName: "commission",
        title: "memberMgmt.commission.title",
      },
      {
        no: "2.5",
        pageName: "credit",
        title: "memberMgmt.credit.title",
      },
      {
        no: "2.6",
        key: "PRESET_PT",
        pageName: "preset-pt",
        title: "memberMgmt.presetPT.title",
      },
      {
        no: "2.7",
        key: "EFFECTIVE_PT",
        pageName: "effective-pt",
        title: "memberMgmt.effectivePT.title",
      },
    ],
  },
  {
    no: "3.",
    title: "report.title",
    activeDropdown: true,
    key: "REPORT",
    subMenu: [
      {
        no: "3.1",
        pageName: "winlose-simple",
        title: "report.winloseSimple",
      },
      {
        no: "3.2",
        pageName: "statement",
        title: "report.statement",
      },
      {
        no: "3.3",
        pageName: "match-result",
        title: "report.matchResult",
      },
      {
        no: "3.4",
        pageName: "ticket-cancel",
        title: "report.cancelBetList",
      },
    ],
  },
  {
    no: "4.",
    title: "account.title",
    activeDropdown: true,
    key: "ACCOUNT",
    subMenu: [
      {
        no: "4.1",
        pageName: "announcements",
        title: "account.announcement",
      },
      {
        no: "4.2",
        pageName: "my-profile",
        title: "account.myProfile",
      },
      {
        no: "4.3",
        pageName: "change-password",
        title: "account.changePassword.title",
      },
      // {
      //   no: "4.4",
      //   // pageName: "categories",
      //   title: "Web Link",
      // },
    ],
  },
  {
    no: "5.",
    title: "alias.title",
    activeDropdown: true,
    key: "ALIAS",
    subMenu: [
      {
        no: "5.1",
        pageName: "alias-management",
        title: "alias.aliasManagement",
      },
    ],
  },
  {
    no: "6.",
    // pageName: "dashboard",
    title: "printSlip.title",
    activeDropdown: true,
    subMenu: [
      {
        no: "6.1",
        pageName: "hot-to-setup",
        title: "printSlip.howToSetup",
      },
      {
        no: "6.2",
        pageName: "update-print-slip",
        title: "printSlip.updatePrintSlip",
      },
      {
        no: "6.3",
        pageName: "download-print-slip",
        title: "printSlip.downloadPrintSlip",
      },
    ],
  },
];

export default menu;
