import numbro from "numbro";

export function formaCurrencyNumber(value: number | string, mantissa?: number) {
  if (Number(value)) {
    return numbro(value).format({
      average: Number(value) > 9999999,
      thousandSeparated: true,
      mantissa: !!mantissa || mantissa === 0 ? mantissa : 2,
    });
  }
  return 0;
}

export function formatDecimalNumber(value: number | string, mantissa?: number) {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: !!mantissa || mantissa === 0 ? mantissa : 2,
  });
}

export function formatNumberOptions(
  value: number | string,
  option?: numbro.Format
) {
  if (value) {
    return numbro(value).format(option);
  }
  return 0;
}

export function formatNumber(value: number | string) {
  if (value) {
    return numbro(value).format({
      thousandSeparated: true,
      trimMantissa: true,
      mantissa: 0,
    });
  }
  return 0;
}
