import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layout/MainLayout.vue";
import PublicLayout from "@/layout/PublicLayout.vue";

import Login from "@/pages/auth/Login.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      redirect: "/login",
      component: PublicLayout,
      meta: { requiredAuth: false },
      children: [
        {
          path: "/login",
          name: "login",
          component: Login,
        },
      ],
    },
    {
      path: "/",
      name: "main",
      redirect: "my-profile",
      component: MainLayout,
      meta: { requiredAuth: true },
      children: [
        {
          path: "/my-profile",
          name: "my-profile",
          component: () => import("@/pages/account/ProfileInformation.vue"),
        },
        {
          path: "/new-account",
          name: "new-account",
          component: () => import("@/pages/member/NewAccount.vue"),
        },
        {
          path: "/announcements",
          name: "announcements",
          component: () => import("@/pages/account/Announcements.vue"),
        },
        {
          path: "/total-bet",
          name: "total-bet",
          children: [
            {
              path: "hdp-ou",
              name: "hdp-ou",
              component: () => import("@/pages/total-bet/TotalBet.vue"),
            },
            {
              path: "fh-hdp-ou",
              name: "fh-hdp-ou",
              component: () => import("@/pages/total-bet/TotalBet.vue"),
            },
            {
              path: "ht-hdp-ou",
              name: "ht-hdp-ou",
              component: () => import("@/pages/total-bet/TotalBet.vue"),
            },
            {
              path: "ft-oe",
              name: "ft-oe",
              component: () => import("@/pages/total-bet/TotalBet.vue"),
            },
            {
              path: "step",
              name: "step",
              component: () => import("@/pages/total-bet/TotalBet.vue"),
            },
            {
              path: "single",
              name: "single",
              component: () => import("@/pages/total-bet/TotalBet.vue"),
            },
            {
              path: "member",
              name: "member",
              component: () =>
                import("@/pages/total-bet/MemberOutstandingPage.vue"),
            },
            {
              path: "ticket/matchId=:matchId&oddType=:oddType",
              name: "ticket-total-bet",
              component: () => import("@/pages/total-bet/TicketPage.vue"),
            },
            {
              path: "ticket/matchId=:matchId&parlay=:parlay",
              name: "ticket-total-bet",
              component: () => import("@/pages/total-bet/TicketPage.vue"),
            },
          ],
        },
        {
          path: "/ticket",
          name: "ticket",
          children: [
            {
              path: "matchId=:matchId&oddType=:oddType",
              name: "ticket-total-bet",
              component: () => import("@/pages/total-bet/TicketPage.vue"),
            },
            {
              path: "matchId=:matchId&parlay=:parlay",
              name: "ticket-parlay",
              component: () => import("@/pages/total-bet/TicketPage.vue"),
            },
            {
              path: "",
              name: "ticket-member",
              component: () =>
                import("@/pages/total-bet/MemberOutstandingPage.vue"),
            },
          ],
        },
        {
          path: "/commission",
          name: "commission",
          component: () => import("@/pages/member/Commission.vue"),
        },
        {
          path: "/account-list",
          name: "account-list",
          component: () => import("@/pages/member/AccountList.vue"),
        },
        {
          path: "/credit",
          name: "credit",
          component: () => import("@/pages/member/Credit.vue"),
        },
        {
          path: "/betting",
          name: "betting",
          component: () => import("@/pages/member/Betting.vue"),
        },
        {
          path: "/preset-pt",
          name: "preset-pt",
          component: () => import("@/pages/member/PresetPT.vue"),
        },
        {
          path: "/share",
          name: "share",
          children: [
            {
              path: "effective-pt",
              name: "effective-pt",
              component: () =>
                import(
                  "@/pages/member/member-share/MemberShareEffectivePage.vue"
                ),
            },
          ],
        },
        {
          path: "/account/change-password",
          name: "change-password",
          component: () => import("@/pages/account/ChangePassword.vue"),
        },
        {
          path: "/winlose",
          name: "winlose",
          children: [
            {
              path: "index",
              name: "winlose-simple",
              component: () => import("@/pages/report/WinloseSimplePage.vue"),
            },
            {
              path: "statement",
              name: "statement",
              component: () => import("@/pages/report/StatementPage.vue"),
            },
            {
              path: "result",
              name: "match-result",
              component: () => import("@/pages/report/MatchResultPage.vue"),
            },
            {
              path: "ticket/dateFrom=:dateFrom&dateTo=:dateTo",
              name: "ticket-winlose",
              component: () => import("@/pages/report/WinloseSimplePage.vue"),
            },
            {
              path: "cancel",
              name: "ticket-cancel",
              component: () =>
                import("@/pages/report/CancelledBetListPage.vue"),
            },
          ],
        },
        {
          path: "/hot-to-setup",
          name: "hot-to-setup",
          component: () => import("@/pages/print-slip/HowToSetup.vue"),
        },
        {
          path: "/update-print-slip",
          name: "update-print-slip",
          component: () => import("@/pages/print-slip/UpdatePrintSlip.vue"),
        },
        {
          path: "/alias-management",
          name: "alias-management",
          component: () => import("@/pages/user-alias/UserAliasManagement.vue"),
        },
      ],
    },
    {
      path: "/error-page",
      name: "error-page",
      component: () => import("@/pages/ErrorPage.vue"),
    },
  ],
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("authToken");
  if (!token) {
    if (to.name !== "login") {
      return next({ name: "login" });
    }
    return next();
  }
  if (
    (token !== null || token !== undefined || (token as string).length > 0) &&
    to.name === "login"
  ) {
    next({ name: "main" });
  }
  return next();
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
