<script setup lang="ts">
import "@/assets/css/themes/enigma/side-nav.css";
import { useRoute, useRouter } from "vue-router";
import Tippy from "@/components/Base/Tippy";
import Lucide from "@/components/Base/Lucide";
import TopBar from "@/components/TopBar";
import { useMenuStore } from "@/stores/menu";
import {
  type ProvideForceActiveMenu,
  forceActiveMenu,
  type Route,
  type FormattedMenu,
  nestedMenu,
  linkTo,
  enter,
  leave,
} from "./side-menu";
import { watch, reactive, ref, computed, onMounted, provide } from "vue";
import { useAuthStore } from "@/stores/auth";

const route: Route = useRoute();
const router = useRouter();
let formattedMenu = reactive<Array<FormattedMenu | "divider">>([]);
const setFormattedMenu = (
  computedFormattedMenu: Array<FormattedMenu | "divider">
) => {
  Object.assign(formattedMenu, computedFormattedMenu);
};
const menuStore = useMenuStore();
const authStore = useAuthStore();

const windowWidth = ref(window.innerWidth);
const showScrollToTopBtn = ref(false);

const menu = computed(() => nestedMenu(menuStore.menu("side-menu"), route));
const session = computed(() => authStore.sessionMember);

provide<ProvideForceActiveMenu>("forceActiveMenu", (pageName: string) => {
  forceActiveMenu(route, pageName);
  setFormattedMenu(menu.value);
});

function hasPermission(key?: string) {
  if (session.value?.userAlias) {
    if (key === "TOTAL_BET") {
      return session.value?.userAlias.isTotalBet;
    }
    if (key === "MEMBER_MGMT") {
      return session.value?.userAlias.isMember ?? 1 > 0;
    }
    if (key === "NEW_ACCOUNT") {
      return session.value?.userAlias.isMember === 2;
    }
    if (key === "REPORT") {
      return session.value?.userAlias.isWinlose;
    }
    if (key === "ACCOUNT") {
      return session.value?.userAlias.isAccount;
    }
    if (key === "ALIAS") {
      return !session.value?.userAlias;
    }
  }
  return true;
}

function isShowPresetPT(key?: string) {
  if (key === "PRESET_PT" && session.value) {
    return session.value.role >= 3;
  }
  return true;
}

function getMenuNO(menu: FormattedMenu) {
  if (session.value && session.value.role < 3 && menu.key === "EFFECTIVE_PT") {
    return "2.6";
  }
  return menu.no;
}

function onScrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

watch(menu, () => {
  setFormattedMenu(menu.value);
});

watch(
  computed(() => route.path),
  () => {
    delete route.forceActiveMenu;
  }
);

onMounted(() => {
  setFormattedMenu(menu.value);
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
  window.addEventListener("scroll", function () {
    if (window.scrollY > 300) {
      showScrollToTopBtn.value = true;
    } else {
      showScrollToTopBtn.value = false;
    }
  });
});
</script>

<template>
  <div class="!bg-white" :class="['enigma md:py-0 sm:px-8 md:px-0']">
    <TopBar layout="side-menu" />
    <div class="flex mt-1">
      <!-- BEGIN: Side Menu -->
      <nav
        class="side-nav !min-w-[210px] p-2 overflow-x-hidden z-50 block bg-nav-bar"
      >
        <ul class="grid gap-1.5">
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'divider'"
              type="li"
              class="my-6 side-nav__divider"
              :key="'divider-' + menuKey"
            />
            <li
              v-if="hasPermission((menu as FormattedMenu).key) && menu !== 'divider'"
              :key="menuKey"
              class="bg-white rounded-md"
            >
              <Tippy
                v-if="menu.pageName !== 'download-print-slip'"
                as="a"
                :content="$t(menu.title)"
                :options="{
                  placement: 'right',
                }"
                :disable="windowWidth > 1260"
                :href="
                  menu.subMenu
                    ? '#'
                    : ((pageName: string | undefined) => {
                        try {
                          return router.resolve({
                            name: pageName,
                          }).fullPath;
                        } catch (err) {
                          return '';
                        }
                      })(menu.pageName)
                "
                @click="(event: MouseEvent) => {
                  event.preventDefault();
                  linkTo(menu, router);
                  setFormattedMenu([...formattedMenu]);
                }"
                :class="[
                  menu.active ? 'side-menu side-menu--active' : 'side-menu',
                ]"
              >
                <!-- <div class="side-menu__icon">
                  <Lucide :icon="menu.icon" />
                </div> -->
                <div class="side-menu__title bg-side-menu__title rounded-md">
                  <div
                    v-if="menu.subMenu"
                    class="mr-2 bg-white w-3 h-3 rounded-full"
                    :class="[
                      'side-menu__sub-icon',
                      { 'transform rotate-180': menu.activeDropdown },
                    ]"
                  >
                    <Lucide
                      :icon="menu.activeDropdown ? 'Minus' : 'Plus'"
                      class="text-black !w-3 !h-3 stroke-2"
                    />
                  </div>
                  <span class="text-xs font-black text-white">
                    {{ menu.no }}
                    {{ $t(menu.title) }}
                  </span>
                </div>
              </Tippy>
              <Transition @enter="enter" @leave="leave">
                <ul
                  v-if="menu.subMenu && menu.activeDropdown"
                  class="py-1"
                  :class="{ 'side-menu__sub-open': menu.activeDropdown }"
                >
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                    class="hover:bg-slate-200 px-3"
                  >
                    <Tippy
                      v-if="isShowPresetPT((subMenu as FormattedMenu).key) && subMenu.pageName === 'download-print-slip' && hasPermission((subMenu as FormattedMenu).key)"
                      as="a"
                      :content="$t(subMenu.title)"
                      :options="{
                        placement: 'right',
                      }"
                      :disable="windowWidth > 1260"
                      href="https://printslip.sgp1.digitaloceanspaces.com/league88/League88-Setup-Prod-2.1.6.exe"
                      class="!rounded-none"
                      :class="[
                        subMenu.active
                          ? 'side-menu side-menu--active'
                          : 'side-menu',
                      ]"
                    >
                      <!-- <div class="side-menu__icon">
                        <Lucide :icon="subMenu.icon" />
                      </div> -->
                      <div class="side-menu__title">
                        <span class="text-xs">
                          {{ subMenu.no }}
                          {{ $t(subMenu.title) }}
                        </span>
                      </div>
                    </Tippy>
                    <Tippy
                      v-if="isShowPresetPT((subMenu as FormattedMenu).key) && subMenu.pageName !== 'download-print-slip' && hasPermission((subMenu as FormattedMenu).key)"
                      as="a"
                      :content="$t(subMenu.title)"
                      :options="{
                        placement: 'right',
                      }"
                      :disable="windowWidth > 1260"
                      :href="
                        subMenu.subMenu
                          ? '#'
                          : ((pageName: string | undefined) => {
                              try {
                                return router.resolve({
                                  name: pageName,
                                }).fullPath;
                              } catch (err) {
                                return '';
                              }
                            })(subMenu.pageName)
                      "
                      class="!rounded-none"
                      :class="[
                        subMenu.active
                          ? 'side-menu side-menu--active'
                          : 'side-menu',
                      ]"
                      @click="(event: MouseEvent) => {
                        event.preventDefault();
                        linkTo(subMenu, router);
                        setFormattedMenu([...formattedMenu]);
                      }"
                    >
                      <!-- <div class="side-menu__icon">
                        <Lucide :icon="subMenu.icon" />
                      </div> -->
                      <div class="side-menu__title">
                        <span class="text-xs">
                          {{ getMenuNO(subMenu) }}
                          {{ $t(subMenu.title) }}
                        </span>
                      </div>
                    </Tippy>
                    <Transition
                      @enter="enter"
                      @leave="leave"
                      v-if="subMenu.subMenu"
                    >
                      <ul
                        v-if="subMenu.subMenu && subMenu.activeDropdown"
                        :class="{
                          'side-menu__sub-open': subMenu.activeDropdown,
                        }"
                      >
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <Tippy
                            as="a"
                            :content="$t(lastSubMenu.title)"
                            :options="{
                              placement: 'right',
                            }"
                            :disable="windowWidth > 1260"
                            :href="
                              lastSubMenu.subMenu
                                ? '#'
                                : ((pageName: string | undefined) => {
                                    try {
                                      return router.resolve({
                                        name: pageName,
                                      }).fullPath;
                                    } catch (err) {
                                      return '';
                                    }
                                  })(lastSubMenu.pageName)
                            "
                            :class="[
                              lastSubMenu.active
                                ? 'side-menu side-menu--active'
                                : 'side-menu',
                            ]"
                            @click="(event: MouseEvent) => {
                              event.preventDefault();
                              linkTo(lastSubMenu, router);
                              setFormattedMenu([...formattedMenu]);
                            }"
                          >
                            <!-- <div class="side-menu__icon">
                              <Lucide :icon="lastSubMenu.icon" />
                            </div> -->
                            <div class="side-menu__title">
                              {{ $t(lastSubMenu.title) }}
                            </div>
                          </Tippy>
                        </li>
                      </ul>
                    </Transition>
                  </li>
                </ul>
              </Transition>
            </li>
          </template>
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div
        class="bg-white"
        :class="[
          'px-4 pb-5 min-h-screen bg-slate-100 flex-1 relative dark:bg-darkmode-700',
        ]"
      >
        <div class="w-[980px]">
          <RouterView />
        </div>
        <div class="sticky bottom-6 flex justify-end">
          <div
            v-if="showScrollToTopBtn"
            class="bg-blue-600 rounded-full p-2 cursor-pointer hover:bg-blue-800"
            @click="onScrollToTop"
          >
            <Lucide icon="ArrowUpToLine" class="text-white stroke-[3px]" />
          </div>
        </div>
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<style lang="postcss">
.bg-nav-bar {
  background: linear-gradient(135deg, #bed7e0 2%, #748c9e 42%, #52626d 100%);
}
.bg-side-menu__title {
  padding-left: 5px;
  padding: 3px 10px;
  background: linear-gradient(to bottom, #eee 0%, #2a437c 40%, #304f8e 90%);
}
</style>
