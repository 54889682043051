<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import SideMenuLayout from "@/layout/SideMenu/SideMenu.vue";

import { useMemberService } from "@/composable/member.service";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const { onFetchMemberProfileByTokenService } = useMemberService();

const session = computed(() => authStore.sessionMember);

async function onFetchMemberProfile() {
  const { data: profileData, response: profileResponse } =
    await onFetchMemberProfileByTokenService();
  if (profileResponse.value?.ok) {
    authStore.$patch({
      sessionMember: profileData.value,
    });
  }
}

onMounted(() => {
  onFetchMemberProfile();
});
</script>

<template>
  <SideMenuLayout v-if="session" />
</template>
