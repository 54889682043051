import { createFetch } from "@vueuse/core";
import router from "../router";

export const useInternalFetchWithAuth = createFetch({
  baseUrl: `${import.meta.env.VITE_APP_API_URL}`,
  combination: "overwrite",
  options: {
    updateDataOnError: true,
    async beforeFetch({ options }) {
      const token = localStorage.getItem("authToken");
      options.headers = {
        "Accept-Language": "en",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      return { options };
    },
    async onFetchError(ctx): Promise<any> {
      if (ctx.response && !ctx.response.ok) {
        if (ctx.response.status === 401) {
          localStorage.removeItem("authToken");
          await router.push({ name: "login" });
        }
        if (ctx.response.status === 403) {
          await router.push({ name: "forbidden" });
        }
        // console.log(ctx.data.errorMessage)
      }
      return ctx;
    },
  },
});

export const useInternalFetch = createFetch({
  baseUrl: `${import.meta.env.VITE_APP_API_URL}`,
  combination: "overwrite",
  options: {
    updateDataOnError: true,
    async beforeFetch({ options }) {
      options.headers = {
        "Accept-Language": "en",
        "Content-Type": "application/json",
      };
      return { options };
    },
  },
});

export const useInternalFormDataFetch = createFetch({
  baseUrl: `${import.meta.env.VITE_APP_API_URL}`,
  combination: "overwrite",
  options: {
    updateDataOnError: true,
    async beforeFetch({ options }) {
      const token = localStorage.getItem("authToken");
      options.headers = {
        "Accept-Language": "en",
        Authorization: `Bearer ${token}`,
      };
      return { options };
    },
    async onFetchError(ctx): Promise<any> {
      if (ctx.response && !ctx.response.ok) {
        if (ctx.response.status === 401) {
          localStorage.removeItem("authToken");
          await router.push({ name: "login" });
        }
        if (ctx.response.status === 403) {
          await router.push({ name: "forbidden" });
        }
      }
      return ctx;
    },
  }
});
