import { defineStore } from "pinia";
import { Member } from "./member";

export interface State {
  sessionMember: Member | null;
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    sessionMember: null,
  }),
});
