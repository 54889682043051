export enum MemberLevel {
  MEMBER = 1,
  AGENT,
  MASTER,
  SENIOR,
  SUPER,
  COMPANY,
}

export enum MemberLevelLabel {
  "Member" = 1,
  "Agent",
  "Master",
  "Senior",
  "Super",
  "Company",
}

export enum MemberLevelLabelLowerCase {
  "member" = 1,
  "agent",
  "master",
  "senior",
  "super",
  "company",
}

export enum IsClosedUser {
  open = 0,
  close,
  allActive,
}

export enum IsClosedUserLabel {
  "Open" = 0,
  "Close",
  "All Active",
}

export enum BallStep {
  single = 1,
  step,
}

export enum BallStepLabel {
  "Single Bet" = 1,
  "Ball Step",
}

export const TicketStatusList = [
  { value: 0, label: "cancel" },
  { value: 1, label: "waiting" },
  { value: 2, label: "running" },
  { value: 3, label: "complete" },
];

export enum localeMemberLevel {
  "member" = 1,
  "agent",
  "master",
  "senior",
  "super",
  "company",
}
